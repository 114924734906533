import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel, UserResult } from '../classes/user';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AccountService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';
//import { environment } from '../../environments/environment'

const BASE_URL: string = environment.API_URL;

const state = {
    products: JSON.parse(localStorage['products'] || '[]'),
    wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
    compare: JSON.parse(localStorage['compareItems'] || '[]'),
    percent: JSON.parse(localStorage['percent'] || '[]')
}

let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
@Injectable({ providedIn: 'root' })

export class UtilsService {
    currenUser: UserResult;
        
    constructor(private http: HttpClient, private accountService: AccountService, private toastrService: ToastrService) {
    this.currenUser = this.accountService.userValue;
    }

    public getCatalogByName(name: string): Observable<any>{
        return this.http.get<any>(`${BASE_URL}/api/catalogs/${name}`, { headers: { 'Content-Type': 'application/json' } });
    }

    public getNews(){
      return this.http.get<any>(`${BASE_URL}/api/newstoshow`, { headers: { 'Content-Type': 'application/json' } });
    }

    public get percent(): Observable<any[]> {
        const itemsStream = new Observable(observer => {
          observer.next(state.percent);
          observer.complete();
        });
        return <Observable<any[]>>itemsStream;
      }
  
    public getNewsbyTitle(title:any){
      return this.http.get<any>(`${BASE_URL}/api/newsname?title=${title}`, { headers: { 'Content-Type': 'application/json' } });
    }

    public getNewsbyCategory(category:any){
      return this.http.get<any>(`${BASE_URL}/api/news/${category}`, { headers: { 'Content-Type': 'application/json' } });
    }
  
    
}