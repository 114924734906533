<div class="slide one">
    <div class="container align-items-center info-curtain" style="display: block; opacity: 0;" >
        <img [src]="logo_cortinilla" class="float-left curtain-image">
        <h1 class="text-white float-left ml-5">Somos la comunidad preventiva más grande del  mundo</h1>
    </div>
</div>
<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<div class="fixed-bottom cookies-popup" *ngIf="showModal">
    <div class="container">
        <div class="row py-5 justify-content-center">
            <div class="col-lg-5 col-md-10 col-sm-12 d-flex align-items-center">
                <h4>
                    <p class="text-black font-weight-bold">Cookies</p>
                    Con su acuerdo, nosotros y nuestros socios usamos cookies o tecnologías similares para almacenar, acceder y procesar datos personales como su visita en este sitio web.
                    <a [routerLink]="'/pages/terms'">Saber más.</a>
                </h4>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 text-center d-flex align-items-end">
                <a (click)="closeModal()" class="btn btn-sm btn-red">Aceptar</a>
            </div>
        </div>
    </div>
</div>
