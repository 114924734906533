import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/hub/logos/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  browser: any;
  back4: string;

  constructor() {
    this.browser = localStorage['browser'];

    this.back4 = this.browser !== 'safari' ? '../../../../assets/images/hub/fondos/fondo_7_1920X1080.webp' : '../../../../assets/images/hub/fondos/fondo_7_1920X1080.jpg';
  }

  ngOnInit(): void {
  }

}
