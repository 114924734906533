import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UserResult } from '../shared/classes/user';
import { AccountService } from '../shared/services/authentication.service';
import { SubscriptionService } from '../shared/services/subscription.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {

  public currentUser: UserResult;
  public uuid: string;
  public status: number = 1;
  public invitation: any;
  public loader = false;
  infoData: any;

  constructor(private router: ActivatedRoute, private subscriptionService: SubscriptionService, private route: Router, 
    private accountService: AccountService, private titleService: Title) {
    this.titleService.setTitle("Stackcode | Invitacion")
    this.currentUser = JSON.parse( localStorage.getItem('user_session') );
    this.router.paramMap.subscribe( params=>{
      this.uuid = params.get("uuid");
      this.subscriptionService.getSubscriptionValue(this.uuid).subscribe( data=>{
        this.loader = true;
        this.infoData = data;
        if( !data.subscription.Status ){
          if( !this.currentUser ){
            this.status = 0;
            setTimeout(() => this.route.navigate( ['/account/login'], {queryParams: {returnUrl: this.uuid}} ), 7000); 
          }else{
            if( this.currentUser.email === data.subscription.Email ){
              let subscription = { uuid: data.subscription.UUID, userId: this.accountService.userValue.id };
              this.subscriptionService.updateSubscriptionStatus( subscription, this.accountService.userValue.token ).subscribe( data=>{
                this.status = 1;
                setTimeout(() => this.route.navigateByUrl('/school/home'), 10000); 
              } );
            }else{
              this.status = 2;
              setTimeout(() => this.route.navigateByUrl('/home'), 10000); 
            }
          }
        }else{
          if( this.currentUser.email === data.subscription.Email ){
            this.status = 3;
            setTimeout(() => this.route.navigateByUrl('/school/home'), 10000); 
          }else{
            this.status = 4;
            setTimeout(() => this.route.navigateByUrl('/home'), 10000); 
          }
        } 
      }); 
    });
   }

  ngOnInit(): void {
  }

}
