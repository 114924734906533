<!--footer section -->
<footer class="h-100">
	<section class="section_footer section-b-space pb-0" style="background-image: url(../../../../assets/images/footer/cuadricula.png); background-size: 60%; background-position: top right;" [ngStyle]="">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-2 col-md-2 col-sm-12 text-center">
					<img width="100%" class="mr-5 img-footer" src="../../../../assets/images/hub/logos/logo_menu.png" alt="">
				</div>
				<div class="col-lg-6 col-md-7 col-sm-12" style="margin: auto 0;">
					<div class="footer-contant">
						<h4>
							<a href="javascript:void(0)" style="font-size: 80%;">
								<img class="mr-3" src="../../../../assets/images/icons/TELEFONO-CONTACTO.png" alt="">
								<strong class="text-white">
									<a href="tel:2296389864">(229) 638 9864 </a>| <a href="tel:2296398693">(229) 639 8693</a></strong>
							</a>
						</h4>
						<h4><a href="javascript:void(0)" style="font-size: 65%;" class="text-lowercase mail">
							<img class="mr-3" src="../../../../assets/images/icons/MAIL-CONTACTO.png" alt="">
							<a href="mailto:direccion@elhubdeseguridad.com"><strong style="padding: 5px 15px; background: white; border-radius: 10px;">direccion@elhubdeseguridad.com</strong></a>
						</a></h4>
						<h4><a href="https://www.elhubdeseguridad.com" style="font-size: 65%; letter-spacing: 6px;" class="text-lowercase text-white">
							www.elhubdeseguridad.com
						</a></h4>
					</div>
				</div>
				<div class="col-md-3 text-right">
					<div class="sub-title">
                        <div class="footer-contant">
							<p style="color: white; text-transform: uppercase; font-weight: bold;">Síguenos en redes sociales</p>
							<div class="footer-social" style="margin-top: 16px !important;">
								<ul>
									<li class="float-left p-0">
										<a href="javascript:void(0)"><img width="40%" src="../../../../assets/images/icons/FACEBOOK_CONTACTO.png" alt=""></a>
									</li>
									<li class="float-left p-0">
										<a href="javascript:void(0)"><img width="40%" src="../../../../assets/images/icons/INSTAGRAM_CONTACTO.png" alt=""></a>
									</li>
									<li class="float-left p-0">
										<a href="javascript:void(0)"><img width="40%" src="../../../../assets/images/icons/LINKEDIN_CONTACTO.png" alt=""></a>
									</li>
									<li class="float-left p-0">
										<a href="javascript:void(0)"><img width="40%" src="../../../../assets/images/icons/TICKTOK_CONTACTO.png" alt=""></a>
									</li>
								</ul>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
		<div class="sub-footer">
			<div class="">
				<div class="row">
					<div class="col-xl-12 col-md-12 col-sm-12">
					  <div class="footer-end">
						<ul class="w-100 justify-content-between ulFooter">
							<li class="lr"><p class="text-white">Copyright<i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} ElHubDeSeguridad.com</p></li>
							<li class="lr"><p class="text-white"><a [routerLink]="'/pages/terms'" class="text-white">Términos y condiciones</a></p></li>
							<li class="lr"><p class="text-white"><a [routerLink]="'/pages/privacy'" class="text-white">Aviso de privacidad</a></p></li>
						</ul>
					  </div>
					</div>
				</div>
			</div>
		</div>
	</section>
</footer>
<!--footer section end -->