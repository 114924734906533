<div>
    <div class="img-wrapper">
      <div class="lable-block">
        <span class="lable3" *ngIf="product.new">new</span>
        <span class="lable4" *ngIf="product.sale">on sale</span>
      </div>
      <div class="front p-5">
        <a [routerLink]="['/pages/biography/a']">
          <img 
            [src]="product.image" 
            class="img-fluid lazy-loading img_expert" />
        </a>
        <div class="mt-5 pt-3 text-left">
          <a [routerLink]="['/pages/biography/a']">
            <h6 class="font-weight-bold product-title">{{ product?.title | titlecase }}</h6>
            <p class="product-description">{{ product.subTitle }}</p>
          </a>
        </div>
      </div>
  </div>