import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { CourseComponent } from './course/course.component'
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { AccountComponent } from './account/account.component';
import { InvitationsComponent } from './invitations/invitations.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'invitations/:uuid',
    component: InvitationsComponent
  },
  {
    path: 'reset-password/:uuid',
    component: InvitationsComponent
  },
  {
    path: 'account',
    component: AccountComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'course',
    component: CourseComponent,
    loadChildren: () => import('./course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'preview',
    component: CourseComponent,
    loadChildren: () => import('./course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'school',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: 'validate',
    component: ShopComponent,
    loadChildren: () => import('./validate/validate.module').then(m => m.ValidateModule)
  },
  {
    path: 'education',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'pages/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
