<!--footer section -->
<footer [ngClass]="class">
    <div class="" *ngIf="mainFooter">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="logo-footer col-sm-4 col-md-2 col-lg-2">
                        <img class="imgFooter" src="../../../../assets/images/hub/logos/logo.png" width="100%" alt="">
                    </div>
                </div>
                <div class="row footer-theme2 justify-content-between">
                    <div class="order-lg-1 col-md-5 order-md-1 col-sm-12 col-lg-4 offset-lg-2">
                        <ul class="icons-social d-flex justify-content-around">
                            <li>
                                <a target="_blank" href="https://www.facebook.com/stackcodeio/">
                                    <img src="../../../../assets/images/home/facebook.png" width="100%" alt="">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/stackcodeio/">
                                    <img src="../../../../assets/images/home/instagram.png" width="100%" alt="">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.youtube.com/">
                                    <img src="../../../../assets/images/home/youtube.png" width="100%" alt="">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.tiktok.com/">
                                    <img src="../../../../assets/images/home/tiktok.png" width="100%" alt="">
                                </a>
                            </li>
                        </ul>
                        <p class="social-link text-center">stackcode.io</p>
                    </div>
                    <div class="order-lg-0 col-md-7 order-md-0 row col-sm-12 col-lg-6 pr-0">
                        <a class="w-100" href="mailto:direccion@elhubdeseguridad.com">
                        <div class="col-sm-12 pr-0">
                            <div class="footer-rounded d-flex align-items-center">
                                <div class="img-rounded d-flex align-items-center">
                                    <img src="../../../../assets/images/home/mail.png" alt="">
                                </div>
                                <div class="footer-info">
                                        <p class="m-0">luis@stackcode.io</p>
                                </div>
                            </div>
                        </div>
                        </a>
                        <div class="col-sm-6 pr-0">
                                <a href="telto:2296389854">
                                <div class="footer-rounded d-flex align-items-center">
                                    <div class="img-rounded d-flex align-items-center">
                                        <img src="../../../../assets/images/home/arrow-phone.png" alt="">
                                    </div>
                                    <div class="footer-info">
                                        <p class="m-0">(229) 111 0000</p>
                                    </div>
                                </div>
                            </a>
                            </div>
                            <div class="col-sm-6 pr-0">
                                <a href="telto:2296398693">
                                <div class="footer-rounded d-flex align-items-center">
                                    <div class="img-rounded d-flex align-items-center">
                                        <img src="../../../../assets/images/home/arrow-phone.png" alt="">
                                    </div>
                                    <div class="footer-info">
                                        <p class="m-0">(229) 111 0000</p>
                                    </div>
                                </div>
                            </a>
                            </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 copirigth" *ngIf="innerWidth > 578">
                        <p class="text-white">Copyright<i class="fal fa-copyright"></i> {{
                            today | date:'y'}} Stackcode.io</p>
                    </div>
                    <div class="col-sm-12 col-md-6 copirigth p-0">
                        <ul class="d-flex justify-content-around">
                            <li>
                                <p class="text-white"><a [routerLink]="'/pages/privacy'" class="text-white">Aviso de
                                    privacidad</a></p>
                            </li>
                            <li>
                                <p class="text-white"><a [routerLink]="'/pages/terms'" class="text-white">Términos y
                                        condiciones</a></p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-12 col-md-6 mt-2 copirigth" *ngIf="innerWidth < 579">
                        <p class="text-white cp">Copyright <i class="fas fa-copyright"></i> {{
                            today | date:'y'}} El Stackcode</p>
                    </div>
                </div>
            </section>
        </div>
        <div class="sub-footer">
            <div class="">
                <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                        <div class="footer-end">
                            <!-- <ul class="w-100 justify-content-between ulFooter">
                                <li class="lr">
                                    <p class="text-white">Copyright<i class="fa fa-copyright" aria-hidden="true"></i> {{
                                        today | date:'y'}} ElHubDeSeguridad.com</p>
                                </li>
                                <li class="lr">
                                    <p class="text-white"><a [routerLink]="'/pages/terms'" class="text-white">Términos y
                                            condiciones</a></p>
                                </li>
                                <li class="lr">
                                    <p class="text-white"><a [routerLink]="'/pages/privacy'" class="text-white">Aviso de
                                            privacidad</a></p>
                                </li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->