import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product, Products } from '../classes/product';
import { environment } from 'src/environments/environment';
import { AccountService } from './authentication.service';
import { UserResult } from '../classes/user';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  topics: JSON.parse(localStorage['topics'] || '[]')
}

const BASE_URL: string = environment.API_URL;
const httpOptions = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class StudyService {
    
  public currentUser: UserResult;
  public httpHeader;
  
  constructor(private http: HttpClient, private accountService: AccountService, private toastrService: ToastrService) {
    this.currentUser = this.accountService.userValue;
  }

  getInfoSubscription(UUID: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.get<any>(`${BASE_URL}/api/subscriptionInfo/${UUID}`, { headers : this.httpHeader }).pipe(map(data => data));
  }

  public checkComments(id: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.post<any[]>(`${BASE_URL}/api/countComments/${id}`, { userId: data.id }, { headers : this.httpHeader }).pipe(map(data => data));
  }

  getPermission(UUID: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.get<any[]>(`${BASE_URL}/api/right-to-examination/${UUID}`, { headers : this.httpHeader }).pipe(map(data => data));
  }
  
  getProgramByUUID(uuid: any): Observable<any[]> {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.get<any[]>(`${BASE_URL}/api/studyprogramby?UUID=${uuid}`, { headers : this.httpHeader }).pipe(map(data => data));
  }

  updatePDFInfo(UUID: any) {
    let data = JSON.parse(localStorage["user_session"]);
    const httpOptions = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', data.token);
    const headers = new HttpHeaders({'Content-Type':'application/json','Authorization':data.token});
    return this.http.put<any>(`${BASE_URL}/api/donwloadintro?UUID=${UUID}`, { headers : {'Content-Type':'application/json','Authorization':data.token} }).pipe(map(data => data));
  }

  updatePercent(UUID: any, arg1: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.put<any>(`${BASE_URL}/api/percentajecal/${UUID}`, arg1, { headers : {'Content-Type':'application/json','Authorization':data.token} }).pipe(map(data => data));
  }

  getProgramContent(program: any): Observable<any>{
    return this.http.get<any>(`${BASE_URL}/api/getcontentbysubcription?UUID=${program}`, { headers : this.httpHeader }).pipe(map(data => data));
  }

  getTopicContent(topic: number) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.get<any>(`${BASE_URL}/api/getcontent/${topic}`, { headers : this.httpHeader }).pipe(map(data => data));
  }

  getEvaluation(StudyProgramId: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.get<any>(`${BASE_URL}/api/getactiveEvaluation/${StudyProgramId}`, { headers : this.httpHeader }).pipe(map(data => data));
  }

  createExam(userEvaluation: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.post<any>(`${BASE_URL}/api/generatevaluation`, userEvaluation, { headers : this.httpHeader }).pipe(map(data => data));
  } 

  saveResponses( id: any, userResponses: any ) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token).set("Content-Type", "application/json");
    return this.http.put<any>(`${BASE_URL}/api/exam/${id}`, userResponses, { headers : this.httpHeader }).pipe(map(data => data));
} 
  
  public get content(): Observable<any[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.topics);
      observer.complete();
    });
    return <Observable<any[]>>itemsStream;
  }

}
