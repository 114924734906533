import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel, UserResult } from '../classes/user';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const BASE_URL: string = environment.API_URL;
let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' as 'json' };
@Injectable({ providedIn: 'root' })

export class AccountService {
        
    private userSubject: BehaviorSubject<any>;
    public user: Observable<any>;
    
    constructor(
        private router: Router,
        private http: HttpClient
        ) {
            this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user_session')));
            this.user = this.userSubject.asObservable();
        }
        
    public get userValue(): UserResult {
        return JSON.parse( localStorage.getItem('user_session') );
    }

    addToFav(favorite: any) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.userValue.token)};
        return this.http.post(`${BASE_URL}/api/favorite`, favorite, httpHeaders );
    }

    checkUserData() {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post<any>(`${BASE_URL}/api/auth/activate/`, httpHeaders );
    }

    activateUser(activateToken: any) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post<any>(`${BASE_URL}/api/auth/activate/${activateToken}`, httpHeaders );
    }

    savePreferences(idUsuario: any, preferences: any) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.put<any>(`${BASE_URL}/api/auth/user-topics/${idUsuario}`, preferences, httpHeaders );
    }

    socialLogin(registerUser: { socialFrom: string; socialId: any; }) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post<any>(`${BASE_URL}/api/auth/social-login`, registerUser, httpHeaders );
      }  

    socialRegister(registerUser: any) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post<any>(`${BASE_URL}/api/auth/social`, registerUser, httpHeaders );
    }

    checkUserName(value: any): any {
        return this.http.get<any>(`${BASE_URL}/api/validateUsername?username=${value}`, httpOptions);
    }

    updatePassword(userUpdate: any) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.userValue.token)};
        return this.http.post(`${BASE_URL}/api/auth/change-password`, userUpdate, httpHeaders );
      }
    
    login(user: any): Observable<any> {
        return this.http.post<UserResult>(`${BASE_URL}/api/auth/signin`, user, httpOptions);
    }
    
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user_session');
        this.userSubject.next(null);
        this.router.navigateByUrl('/pages/login');
    }

    public sendEmail(user: any): Observable<any> {
        return this.http.post<any>(`${BASE_URL}/api/auth/send-email`, user);
    }

    public changeEmail(user: any, token: any): Observable<any> {
        return this.http.post<any>(`${BASE_URL}/api/auth/change-email/${token}`, user);
    }
    
    forgetPassword(email: any): Observable<any> {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post(`${BASE_URL}/api/auth/changenew-password`, email, httpHeaders );
    }

    resetPassword(email: any): Observable<any> {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post(`${BASE_URL}/api/auth/newpassworduptate`, email, httpHeaders );
    }

    public uploadFile(formData: any): Observable<any> {
        let httpHeaders = {headers: new HttpHeaders().set('Authorization', this.userValue.token)};
        return this.http.post<any>(`${BASE_URL}/api/uploadimg`, formData, httpHeaders);
      }
    

    register(user: UserModel) {
        return this.http.post<any>(`${BASE_URL}/api/auth/signup`, user, httpOptions );
    }

    update(user: any) {
        let httpHeaders = {headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.userValue.token)};
        return this.http.put(`${BASE_URL}/api/userinfonoAdmin/${this.userValue.id}`, user, httpHeaders );
    }

    getAll() {
        return this.http.get<UserModel[]>(`/users`);
    }

    getStudentById() {
        let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": this.userValue.token  }) };
        let id = this.userValue.id;
        return this.http.get<UserModel>(`${BASE_URL}/api/student/${id}`, httpOptions);
    }

    getUserById() {
        let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": this.userValue.token  }) };
        let id = this.userValue.id;
        return this.http.get<any>(`${BASE_URL}/api/userinfonoAdmin/${id}`, httpOptions);
    }
}