import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { AccountComponent } from 'src/app/account/account.component';
import { AccountService } from '../../services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  public page: string;
  public isLogged: boolean = false;
  public userName: string;
  public currentUser: any = null;

  constructor(private router: Router, public navServices: NavService, private accountService: AccountService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.page = router.url.toString().split("/").pop();
    if( this.accountService.userValue ){
      this.isLogged = true;
      this.currentUser = this.accountService.userValue;
      this.userName = this.accountService.userValue.displayName.split(" ")[0];
    }
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  public logOut(){
    localStorage.removeItem('user_session');
    console.log(this.page);
    this.page !== 'home' ? this.router.navigateByUrl('/home') : window.location.reload();
  }


  ngOnInit(): void {
  }

  public openMenu(event: any){
    let menu = document.getElementById("dropdownMenu") as HTMLElement;
    if( !menu.classList.contains("show") )
      menu.classList.add("show");
    else
      menu.classList.remove("show");
  }

  public socials = [{
    image: 'assets/images/icons/fb_icon.png',
    url: 'https://www.facebook.com/elhubdeseguridadmx/'
  }, {
    image: 'assets/images/icons/insta_icon.png',
    url: 'https://www.instagram.com/elhubdeseguridadmx/'
  }, {
    image: 'assets/images/icons/tt_icon.png',
    url: 'https://www.tiktok.com/@elhubdeseguridadmx'
  }, {
    image: 'assets/images/icons/yt_icon.png',
    url: 'https://www.youtube.com/channel/UCqlQxayFQPY7fKSYNjlb9_Q'
  }, {
    image: 'assets/images/icons/linked_icon.png',
    url: 'https://www.linkedin.com/company/elhubdeseguridadmx/about/'
  }
];

  mainMenuToggle(): void {
    let body = document.getElementsByClassName("ltr")[0] as HTMLElement;
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
