<div class="icon-nav">
  <ul class="d-flex align-items-center justify-content-end">
    <li class="onhover-div mobile-cart ml-2">
      <div *ngIf="showCart != -1">
        <a [routerLink]="['/account/cart']">
          <i class="fas fa-shopping-cart text-white"></i>
        </a>
      </div>
    </li>
    <li class="onhover-div mobile-cart ml-2" [ngClass]="{'w-25': isLogged}" *ngIf="windowWidth >= 1200">
      <a [routerLink]="'/account/login'" *ngIf="!isLogged" class="btn btn_outline-primary">Inicia Sesión</a>
      <div class="dropdown" *ngIf="isLogged" >
        <a class="btn btn_outline-primary dropdown-toggle" id="dropdownManual" (click)="openMenu($event)"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="../../../../assets/images/hub/profile/user.png" width="80%" alt="">
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownManual" id="dropdownMenu1">
          <a class="dropdown-item" [routerLink]="'/account/home'">Mi cuenta</a>
          <a class="dropdown-item" [routerLink]="'/school/home'">Aula Virtual</a>
          <a class="dropdown-item" *ngIf="currentUser && currentUser.roles === 'business'"
            [routerLink]="'/account/orders'">Mis compras</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)=logOut()>Cerrar sesión</a>
        </div>
      </div>
    </li>
  </ul>
</div>