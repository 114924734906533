import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AccountService } from '../../services/authentication.service';
import { MensajesService } from '../../services/mensajes.service';

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/hub/logos/logo_menu1.webp'; // Default Logo
  @Input() topbar: boolean = false; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public isLogged: boolean = false;
  public userName: string;
  public page: string;
  public currentUser: any = null;
  public prevScrollpos;
  suscripcionMensajes: any;
  url: any;
  showCart: boolean = false;

  constructor(private accountService: AccountService, private mensajesService: MensajesService, private route: Router) {

    let browser = localStorage['browser'];
    this.themeLogo =  browser !== 'safari' ? 'assets/images/hub/logos/logo.png' : 'assets/images/hub/logos/logo.png'
    
    this.url = this.route.url.split("/");
    this.showCart = this.url[2] === "courses" || this.url[2] === "capacitations" || this.url[2] === "events" ? true : false;
    this.page = this.route.url.toString().split("/").pop();
    this.validateLogin()
   }

   public openMenu(event: any){
    let menu = document.getElementById("dropdownMenu1") as HTMLElement;
    if( !menu.classList.contains("show") ){
      menu.classList.add("show");
    }else{
      menu.classList.remove("show");
    }
  }
  
  ngOnInit(): void {
    this.prevScrollpos = window.pageYOffset;
    this.escuchaMensajes();
  }

  private escuchaMensajes(): void {
		this.suscripcionMensajes = this.mensajesService.escucha().subscribe(  // Al llamar a escucha() le pasamos el tema que nos interesa
			msj => {
        this.validateLogin();
			}
		);
	}
  
  gotoLink(){
    if( this.page == "inicio" ){
      window.scrollTo(0,0);
    }else{
      this.route.navigateByUrl("/inicio");
    }
  }

  public logOut(){
    localStorage.removeItem('user_session');
    this.page !== 'inicio' ? this.route.navigateByUrl('/inicio'): window.location.reload();
  }

  // @HostListener Decorator
  
  /* onWindowScroll() {
    var currentScrollPos = window.pageYOffset;
    if (this.prevScrollpos > currentScrollPos || currentScrollPos <= 100  ) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-101px";
    }
    this.prevScrollpos = currentScrollPos;
  } */

  public socials = [{
    image: 'assets/images/icons/fb_icon.png'
  }, {
    image: 'assets/images/icons/insta_icon.png'
  }, {
    image: 'assets/images/icons/linked_icon.png'
  }, {
    image: 'assets/images/icons/tt_icon.png'
  }
];

validateLogin() {
  if(localStorage["user_session"]){
    let date_time = JSON.parse(localStorage["user_session"]);
    if( date_time && moment().diff(date_time.expiresIn, 'minutes') > 0 ){
      Swal.fire({
        title: "La sesión ha expirado, por favor inicia sesión nuevamente",
        icon: "warning",
        position: "bottom-right",
        showConfirmButton: false,
        timer: 2500
      }).then( () => {
        localStorage.removeItem("user_session");
        window.location.reload();
      });
      return;
    }
    this.isLogged = true;
    this.currentUser = this.accountService.userValue;
    this.userName = this.accountService.userValue.displayName.split(" ")[0];
  }
}

}

