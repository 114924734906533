import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { gsap } from 'gsap'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  public logo_cortinilla = "../assets/images/hub/logos/logo-curtain.webp";
  showModal: any = true;
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }

    this.detectBrowserName();

  }
  ngOnInit(): void {
    if( localStorage['cookies'] ){
      this.showModal = JSON.parse( localStorage['cookies'] );
    }
  }

  closeModal(){
    this.showModal = false;
    localStorage.setItem("cookies", JSON.stringify(false));
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        localStorage.setItem('browser', 'edge')
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        localStorage.setItem('browser', 'opera')
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        localStorage.setItem('browser', 'chrome')
        return 'chrome';
      case agent.indexOf('trident') > -1:
        localStorage.setItem('browser', 'ie')
        return 'ie';
      case agent.indexOf('firefox') > -1:
        localStorage.setItem('browser', 'firefox')
        return 'firefox';
      case agent.indexOf('safari') > -1:
        localStorage.setItem('browser', 'safari')
        this.logo_cortinilla = '../assets/images/hub/logos/logo-curtain.png'
        return 'safari';
      default:
        localStorage.setItem('browser', 'other')
        return 'other';
    }
  }
   

  ngOnDestroy(): void {
    localStorage.removeItem("first");
  }

}
