import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AccountService } from '../../services/authentication.service';
import { NavService } from '../../services/nav.service';
import { StudyService } from '../../services/study.service';
import { MensajesService } from '../../services/mensajes.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/hub/logos/logo_menu1.webp'; // Default Logo
  @Input() topbar: boolean = false; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public isLogged: boolean = false;
  public userName: string;
  public page: string;
  public currentUser: any = null;
  public prevScrollpos;
  public topics;
  suscripcionMensajes: any;

  constructor(private accountService: AccountService, private studyService: StudyService, private mensajesService: MensajesService,
    private router: Router, public navServices: NavService, private viewScroller: ViewportScroller,
    private route: ActivatedRoute) {

    let browser = localStorage['browser'];
    this.themeLogo =  browser !== 'safari' ? 'assets/images/hub/logos/logo.png' : 'assets/images/hub/logos/logo.png'

    this.page = this.router.url.toString().split("/").pop();
    if(localStorage["user_session"]){
      let date_time = JSON.parse(localStorage["user_session"]);
      if( date_time && moment().diff(date_time.expiresIn, 'minutes') > 0 ){
        Swal.fire({
          title: "La sesión ha expirado, por favor inicia sesión nuevamente",
          icon: "warning",
          position: "bottom-right",
          showConfirmButton: false,
          timer: 2500
        }).then( () => {
          localStorage.removeItem("user_session");
          window.location.reload();
        });
        return;
      }
      this.isLogged = true;
      this.currentUser = this.accountService.userValue;
      this.userName = this.accountService.userValue.firstName.split(" ")[0];
    }
    this.studyService.content.subscribe(response =>{ this.topics = response; });
    this.page = this.route.url.toString().split("/").pop();
    this.validateLogin()
   }

   public openMenu(event: any){
    let menu = document.getElementById("dropdownMenu1") as HTMLElement;
    if( !menu.classList.contains("show") ){
      menu.classList.add("show");
    }else{
      menu.classList.remove("show");
    }
  }

  scrollToUp(){
    if( this.page == "home" ){
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
       });
    }else{
      this.router.navigateByUrl("/home");
    }
  }

  ngOnInit(): void {
    if( !localStorage['topics'] ){
      localStorage.setItem("topics", '[]');
    }
    this.prevScrollpos = window.pageYOffset;
    this.escuchaMensajes();
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  gotoEvaluation(){
    this.leftMenuToggle();
    this.router.navigateByUrl(this.router.url.split("?")[0]+'/evaluation');
  }

  changeTopic(id: number){
    this.leftMenuToggle();
    this.router.navigate([], { 
      relativeTo: this.route,
      queryParams: { topic: id },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  onHover(menuItem) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  private escuchaMensajes(): void {
		this.suscripcionMensajes = this.mensajesService.escucha().subscribe(  // Al llamar a escucha() le pasamos el tema que nos interesa
			msj => {
        this.validateLogin();
			}
		);
	}
  
  gotoLink(){
    if( this.page == "inicio" ){
      window.scrollTo(0,0);
    }else{
      this.router.navigateByUrl("/inicio");
    }
  }

  public logOut(){
    localStorage.removeItem('user_session');
    this.page !== 'inicio' ? this.router.navigateByUrl('/inicio'): window.location.reload();
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])

  onWindowScroll() {
    var currentScrollPos = window.pageYOffset;
    if (this.prevScrollpos > currentScrollPos || currentScrollPos <= 100  ) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-101px";
    }
    this.prevScrollpos = currentScrollPos;
  }

  public socials = [{
    image: 'assets/images/icons/fb_icon.png'
  }, {
    image: 'assets/images/icons/insta_icon.png'
  }, {
    image: 'assets/images/icons/linked_icon.png'
  }, {
    image: 'assets/images/icons/tt_icon.png'
  }
];
validateLogin() {
  if(localStorage["user_session"]){
    let date_time = JSON.parse(localStorage["user_session"]);
    if( date_time && moment().diff(date_time.expiresIn, 'minutes') > 0 ){
      Swal.fire({
        title: "La sesión ha expirado, por favor inicia sesión nuevamente",
        icon: "warning",
        position: "bottom-right",
        showConfirmButton: false,
        timer: 2500
      }).then( () => {
        localStorage.removeItem("user_session");
        window.location.reload();
      });
      return;
    }
    this.isLogged = true;
    this.currentUser = this.accountService.userValue;
    this.userName = this.accountService.userValue.firstName.split(" ")[0];
  }
}

}

