import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AccountService } from './authentication.service';
import { SubscriptionModel, SubscriptionStatus, SubscriptionValue } from '../classes/subscription';
import { UserResult } from '../classes/user';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

const BASE_URL: string = environment.API_URL;
const httpOptions: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');;
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  
  public httpOption;
  public subsciptions: SubscriptionModel;
  public currenUser: UserResult;
  
  constructor(private http: HttpClient, private accountService: AccountService, private toastrService: ToastrService) {
    this.currenUser = this.accountService.userValue;
  }

  getSubscriptionInfo(uuid: any) {
    return this.http.get<any>(`${BASE_URL}/api/subscription/${uuid}`, {headers: this.httpOption});
  }
  
  public getSubscriptionStatus(uuid: string): Observable<any>{
    this.httpOption = httpOptions.set("Authorization", this.accountService.userValue.token);
    return this.http.get<any>(`${BASE_URL}/api/subscriptionInfo/${uuid}`, {headers: this.httpOption});
  }

  public getSubscriptionValue(uuid: string): Observable<SubscriptionValue>{
    return this.http.get<any>(`${BASE_URL}/api/subscription-info/${uuid}`, {headers: this.httpOption});
  }

  public updateSubscriptionStatus(subsciption: any, token: string): Observable<SubscriptionModel>{
    this.httpOption = httpOptions.set("Authorization", token);
    return this.http.put<SubscriptionModel>(`${BASE_URL}/api/course-acept`, subsciption, {headers: this.httpOption});
  }

  public getSubscriptionByCourse(orderId: string) {
    this.httpOption = httpOptions.set("Authorization", this.accountService.userValue.token);
    return this.http.get<any>(`${BASE_URL}/api/courses/places?ProductId=${orderId}&userId=${this.accountService.userValue.id}`, {headers: this.httpOption});
  }

  public sendEmail(data: any){
    this.httpOption = httpOptions.set("Authorization", this.accountService.userValue.token);
    return this.http.post<any>(`${BASE_URL}/api/cursos/invite`, data, {headers: this.httpOption});
  }

}
