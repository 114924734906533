import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  percent: any;

  constructor() { 
    this.percent = localStorage["percent"];
  }

  ngOnInit(): void {
  }

}
