<!-- header start -->
<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="container py-3">
    <div class="row ">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo p-0">
              <a style="cursor: pointer;" id="headerlogo">
                <img [src]="themeLogo" width="73px" class="logo-top" alt="logo">
              </a>
            </div>
            <app-menu></app-menu>
          </div>
          <div *ngIf="showCart">
            <a [routerLink]="['/account/cart']" style="font-size: 22px;">
              <i class="fas fa-shopping-cart text-white"></i>
            </a>
          </div>
          <div class="menu-right pull-right buttonLogin" *ngIf="!isLogged">
            <a [routerLink]="'/account/login'" class="btn btn_outline-primary">Quiero unirme</a>
          </div>
          <div class="menu-right pull-right  buttonLogin" *ngIf="isLogged">
            <div class="dropdown">
              <button class="btn btn_outline-primary w-100 dropdown-toggle" id="dropdownManual"
                (click)="openMenu($event)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ userName
                }}</button>
              <div class="dropdown-menu w-100" aria-labelledby="dropdownManual" id="dropdownMenu1">
                <a class="dropdown-item" [routerLink]="'/account/home'">Mi cuenta</a>
                <a class="dropdown-item" [routerLink]="'/school/home'">Aula Virtual</a>
                <a class="dropdown-item" *ngIf="currentUser && currentUser.roles === 'business'"
                  [routerLink]="'/account/orders'">Mis compras</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)=logOut()>Cerrar sesión</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->