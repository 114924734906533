<!-- header start -->
<header class="sticky py-3" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div *ngIf="topbar">
        <div class="container">
            <div class="row justify-content-around">
                <div class="py-3 col-sm-5 col-lg-2 text-center">
                    <a class="text-center" [routerLink]="['/inicio']">
                        <img [src]="themeLogo" alt="logo" width="50%">
                    </a>
                </div>
                <div class="col-lg-5 col-sm-12 d-flex align-items-center">
                    <div class="header-contact w-100">
                        <ul>
                            <li class="text-black" style="font-weight: 600;">{{ courseTitle }}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-sm-12 mt-sm-4 justify-content-end d-flex align-items-center">
                    <ul class="header-dropdown" *ngIf="percent !== -1">
                        <li class=" mobile-account" *ngIf="windoWidth > 991 && percent < 50">
                            <circle-progress [percent]="percent" [radius]="30" [outerStrokeWidth]="5"
                                [innerStrokeWidth]="3" [outerStrokeColor]="'#FF0000'" [innerStrokeColor]="'#000000'"
                                [animation]="true" [animationDuration]="300" [showSubtitle]="false"
                                [titleColor]="'#000000'" [unitsColor]="'#000000'"></circle-progress>
                            <a [routerLink]="['/shop/compare']">Tu progreso</a>
                        </li>
                        <li class=" mobile-account" *ngIf="windoWidth > 991 && percent >= 50 && percent < 100">
                            <circle-progress [percent]="percent" [radius]="30" [outerStrokeWidth]="5"
                                [innerStrokeWidth]="3" [outerStrokeColor]="'#FFFF00'" [innerStrokeColor]="'#000000'"
                                [animation]="true" [animationDuration]="300" [showSubtitle]="false"
                                [titleColor]="'#000000'" [unitsColor]="'#000000'"></circle-progress>
                            <a [routerLink]="['/shop/compare']">Tu progreso</a>
                        </li>
                        <li class=" mobile-account" *ngIf="windoWidth > 991 && percent > 99">
                            <circle-progress [percent]="percent" [radius]="30" [outerStrokeWidth]="5"
                                [innerStrokeWidth]="3" [outerStrokeColor]="'#00FF00'" [innerStrokeColor]="'#000000'"
                                [animation]="true" [animationDuration]="300" [showSubtitle]="false"
                                [titleColor]="'#000000'" [unitsColor]="'#000000'"></circle-progress>
                            <a [routerLink]="['/shop/compare']">Tu progreso</a>
                        </li>
                        <li class=" mobile-account2" *ngIf="windoWidth < 992 && percent < 50">
                            <circle-progress [percent]="percent" [radius]="20" [showInnerStroke]="false"
                                [outerStrokeWidth]="5" [innerStrokeWidth]="3" [outerStrokeColor]="'#FF0000'"
                                [innerStrokeColor]="'#000000'" [animation]="true" [animationDuration]="300"
                                [showSubtitle]="false" [titleColor]="'#000000'" [unitsColor]="'#000000'">
                            </circle-progress>
                            <a [routerLink]="['/shop/compare']">Tu progreso</a>
                        </li>
                        <li class=" mobile-account2" *ngIf="windoWidth < 992 && percent >= 50 && percent < 100">
                            <circle-progress [percent]="percent" [radius]="20" [showInnerStroke]="false"
                                [outerStrokeWidth]="5" [innerStrokeWidth]="3" [outerStrokeColor]="'#FFFF00'"
                                [innerStrokeColor]="'#000000'" [animation]="true" [animationDuration]="300"
                                [showSubtitle]="false" [titleColor]="'#000000'" [unitsColor]="'#000000'">
                            </circle-progress>
                            <a [routerLink]="['/shop/compare']">Tu progreso</a>
                        </li>
                        <li class=" mobile-account2" *ngIf="windoWidth < 992 && percent > 99">
                            <circle-progress [percent]="percent" [radius]="20" [showInnerStroke]="false"
                                [outerStrokeWidth]="5" [innerStrokeWidth]="3" [outerStrokeColor]="'#00FF00'"
                                [innerStrokeColor]="'#000000'" [animation]="true" [animationDuration]="300"
                                [showSubtitle]="false" [titleColor]="'#000000'" [unitsColor]="'#000000'">
                            </circle-progress>
                            <a [routerLink]="['/shop/compare']">Tu progreso</a>
                        </li>
                        <li class="ml-3 w-100 py-3">
                            <a class="text-black" [routerLink]="'/school/home'">Volver a mi Aula</a>
                        </li>
                    </ul>
                    <a *ngIf="percent === -1" class="text-black mx-5" [routerLink]="'/school/home'">Volver a mi Aula</a>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header end -->