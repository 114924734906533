import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product, Products } from "../../classes/product";
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AccountService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Products[] = [];
  public search: boolean = false;
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]
  isLogged: boolean;
  currentUser: any;
  userName: any;
  page: any;
  url: string[];
  showCart: number;
  windowWidth: number;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, 
    private translate: TranslateService, private accountService: AccountService,
    public productService: ProductService, private router: Router) {
    this.productService.cartItems.subscribe(response => this.products = response);
    this.page = this.router.url.toString().split("/").pop();
    this.windowWidth = window.innerWidth;
    if(localStorage["user_session"]){
      let date_time = JSON.parse(localStorage["user_session"]);
      if( date_time && moment().diff(date_time.expiresIn, 'minutes') > 0 ){
        Swal.fire({
          title: "La sesión ha expirado, por favor inicia sesión nuevamente",
          icon: "warning",
          position: "bottom-right",
          showConfirmButton: false,
          timer: 2500
        }).then( () => {
          localStorage.removeItem("user_session");
          window.location.reload();
        });
        return;
      }
      this.isLogged = true;
      this.currentUser = this.accountService.userValue;
      this.userName = this.accountService.userValue.firstName.split(" ")[0];
    }
    this.url = router.url.split("/");
    this.showCart = this.url.indexOf("courses");
    console.log(this.showCart);
  }

  public openMenu(event: any){
    let menu = document.getElementById("dropdownMenu1") as HTMLElement;
    if( !menu.classList.contains("show") ){
      menu.classList.add("show");
    }else{
      menu.classList.remove("show");
    }
  }

  public logOut(){
    localStorage.removeItem('user_session');
    this.page !== 'home' ? this.router.navigateByUrl('/home'): window.location.reload();
  }

  ngOnInit(): void {
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
