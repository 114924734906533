import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MensajesService } from '../../services/mensajes.service';
import { StudyService } from '../../services/study.service';
import { UtilsService } from '../../services/utils.services';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})

export class HeaderTwoComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/hub/logos/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  courseTitle: any;
  currentUser: any;
  percent: any;
  windoWidth: number;
  UUID: string;
  suscripcionMensajes: any;
  aviso: any;

  constructor(private utilsService: UtilsService, private router: Router, private route: ActivatedRoute, 
    private programService: StudyService, private mensajesService: MensajesService) {
    this.UUID = this.router.url.split("course/")[1].split("/")[0]
    this.programService.getProgramContent(this.UUID).subscribe(
      data => {
        this.percent = data[0].Percentage;
      }
    );

    this.courseTitle = localStorage['currentCourse'];
    
    this.windoWidth = window.innerWidth;

  }

  ngOnInit(): void {
    this.escuchaMensajes();
  }

  private escuchaMensajes(): void {
		this.suscripcionMensajes = this.mensajesService.escucha().subscribe(  // Al llamar a escucha() le pasamos el tema que nos interesa
			msj => {
        this.percent = msj;
			}
		);
	}
  muestraAviso(msj: any) {
    this.aviso = msj;
    setTimeout(() => {
			this.aviso = '';
		}, 2000);
  }

  ngOnDestroy():void{
    localStorage.removeItem("currentCourse");
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
  }

}
