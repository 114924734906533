<header class="bg_main" [ngClass]="class" id="navbar">
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our store Multikart</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="en">
                    Login
                  </a>
                </li>
                <li>
                  <a data-lng="es">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu container px-0">
          <div class="menu-left w-100">
            <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
              <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()"
                (mouseover)="onHover(false)"></a>
              <nav id="unset" class="bg-light">
                <div (click)="leftMenuToggle()">
                  <div class="sidebar-back text-left m-0"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i>
                    Regresar
                  </div>
                </div>
                <ul id="sub-menu" class="sidebar-menu">
                  <li *ngFor="let topic of topics; let i = index">
                    <div class="card">
                      <div role="tab" class="card-header" id="-header">
                        <button type="button" class="btn btn-link collapsed" aria-expanded="false" aria-controls=""
                          (click)="changeTopic(topic.UUID)"> {{ topic.Title }}</button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="card">
                      <div role="tab" class="card-header" id="-header">
                        <button type="button" class="btn btn-link collapsed" aria-expanded="false" aria-controls=""
                          (click)="gotoEvaluation()"> Ir a la evaluación</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="brand-logo p-0">
              <a (click)="scrollToUp()" style="cursor: pointer;" id="headerlogo">
                <img [src]="themeLogo" class="logo-top py-3" alt="logo" width="120%">
              </a>
            </div>
            <app-menu></app-menu>
            <div class="w-25">
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>