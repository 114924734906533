import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StudyService } from '../../services/study.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public menuItems: any[];
  public topics;

  constructor(private router: Router, public navServices: NavService, private studyService: StudyService, private route: ActivatedRoute, private viewScroller: ViewportScroller) {
    this.studyService.content.subscribe(response =>{ this.topics = response; });
  }

  ngOnInit(): void {
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  gotoEvaluation(){
    let data = this.topics[0].StudyProgramId;
    //console.log(this.router.url);
    this.router.navigateByUrl(this.router.url+'/evaluation');
  }

  changeTopic(id: number){
    this.leftMenuToggle();
    this.router.navigate([], { 
      relativeTo: this.route,
      queryParams: { uuid: id },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  onHover(menuItem) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

}
