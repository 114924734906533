import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public url : any; 

  constructor(private router: Router) {  
      this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
    });
  }
  ngOnInit(): void {
    
  }
}
