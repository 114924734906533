<div class="navbar">
  <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()" 
                                                         (mouseover)="onHover(false)"></a>
    <nav id="unset" class="bg-light">
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-left m-0"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Regresar
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
        <li *ngFor="let topic of topics; let i = index">
          <div class="card">
            <div role="tab" class="card-header" id="-header">
              <button type="button" class="btn btn-link collapsed" aria-expanded="false" aria-controls="" (click)="changeTopic(topic.UUID)" > {{ topic.UUID }}</button>
            </div>
          </div>
        </li>
        <li>
          <div class="card">
            <div role="tab" class="card-header" id="-header">
              <button type="button" class="btn btn-link collapsed" aria-expanded="false" aria-controls="" (click)="gotoEvaluation()"> Ir a la evaluación</button>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</div>

