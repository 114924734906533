<app-header-three></app-header-three>
<section [hidden]="loader" class="p-100">
    <div class="container justify-content-center">
        <div class="col-4 offset-4 mt-5 mb-5 pt-5 pb-5" style="text-align: center;">
            <img src="../../../../assets/images/loaders/infinity.gif" width="100%" alt=""> 
        </div>
    </div>
</section>
<section [hidden]="!loader" class="p-100">
    <div class="container bg-white">
        <div class="row">
            <div class="col-12 p-5 text-center" *ngIf="status === 0">
                <h1>Inicia sesión para disfrutar de tu curso</h1>
                <h4 style="text-transform: initial;">Debes iniciar sesión para inscribirte en el curso que te han invitado</h4>
                <h5 style="text-transform: initial;">Se te redigirá al formulario correspondiente</h5>
                <img src="../../assets/images/hub/proteger.png" class="mt-5" width="30%">
            </div>
            <div class="col-12 p-5 text-center" *ngIf="status === 1">
                <h1>Hola {{ infoData?.subscription.Name }}</h1>
                <h4 style="text-transform: initial;">Te han invitado a unirte al curso: <strong>{{ infoData?.subscription.Product.Title }}</strong></h4>
                <h5 style="text-transform: initial;">Se te redigirá a tu aula virtual para que puedas iniciar tu curso</h5>
                <img src="../../assets/images/hub/proteger.png" class="mt-5" width="30%">
            </div>
            <div class="col-12 p-5 text-center" *ngIf="status === 2">
                <h1>Subscripción no válida</h1>
                <h4 style="text-transform: initial;">El correo con el que tienes tu sesión actual, no corresponde al de la invitación que estas tratando de acceder</h4>
                <h5 style="text-transform: initial;">Por favor inicia sesión con el correo correspondiente</h5>
                <img src="../../assets/images/hub/proteger.png" class="mt-5" width="30%">
            </div>
            <div class="col-12 p-5 text-center" *ngIf="status === 3">
                <h1>Subscripción activa</h1>
                <h4 style="text-transform: initial;">La suscripción a la que quieres acceder ha sido aceptada exitosamente</h4>
                <h5 style="text-transform: initial;">Se te redigirá a tu aula virtual para que puedas iniciar o continuar con tu curso</h5>
                <img src="../../assets/images/hub/proteger.png" class="mt-5" width="30%">
            </div>
            <div class="col-12 p-5 text-center" *ngIf="status === 4">
                <h1>Subscripción no válida</h1>
                <h4 style="text-transform: initial;">El correo con el que tienes tu sesión actual, no corresponde al de la invitación que estas tratando de acceder</h4>
                <img src="../../assets/images/hub/proteger.png" class="mt-5" width="30%">
            </div>
        </div>
    </div>
</section>
<app-footer-two></app-footer-two>