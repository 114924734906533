<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">
      <a href="{{ product?.url }}" target="_blank">
        <img 
          [src]="product.image" 
          class="img-fluid lazy-loading img_expert" />
      </a>
      <div class="mt-5 pt-3 text-left">
        <a href="{{ product?.url }}" target="_blank">
          <div class="container">
            <div class="row">
              <div class="col-1 p-0"><img [src]="product?.icon" style="width: 100% !important;" alt=""></div>
              <div class="col-10"><h6 class="font-weight-bold text-white product-title">{{ product?.title | titlecase }}</h6></div>
            </div>
          </div>
        </a>
      </div>
    </div>
</div>