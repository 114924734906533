// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instagram_token: 'INSTA_TOKEN',
  //API_URL: "https://api.elhubdeseguridad.com",
  frontUrl: "https://demo.s-cool.io",
  API_URL: "https://api.s-cool.io",
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  openpay_id: 'mczjktn4455atwazmvks',
  openpay_apikey: 'pk_73918a4c6216447b926b2fed95030df2',
  GOOGLE_ID: '320519816339-asufc8ekqpbgenrn31901dqjioo0cjj0.apps.googleusercontent.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
