import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product, Products } from '../classes/product';
import { environment } from 'src/environments/environment';
import { AccountService } from './authentication.service';
import { UserResult } from '../classes/user';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

const BASE_URL: string = environment.API_URL;
const httpOptions = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  
  

  public Currency = { name: 'Peso Mexicano', currency: 'MXN', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products
  public currentUser: UserResult;
  public httpHeader;

  constructor(private http: HttpClient, private accountService: AccountService, private toastrService: ToastrService) {
    this.currentUser = this.accountService.userValue;
  }

  getCoursesBuyed(saleOrderId: any): any {
    this.httpHeader = httpOptions.set('Authorization', this.accountService.userValue.token);
    return this.http.get<any>(`${BASE_URL}/api/subscription-admin/${saleOrderId}?userId=${this.accountService.userValue.id}`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  getFavoritesByUser() {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    return this.http.get<any>(`${BASE_URL}/api/favoritelist/${data.id}?limit=99999&offset=0`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  deleteFav(idFav: number) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    return this.http.delete<any>(`${BASE_URL}/api/favoritedel/${idFav}`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  getRatingProduct(productId: any) {
    return this.http.get<any>(`${BASE_URL}/api/rating/${productId}`, { headers: httpOptions }).pipe(map(data => data));
  }

  public rateProduct(productId: number, rateCourse: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    return this.http.post<any>(`${BASE_URL}/api/rate/${productId}`, rateCourse, { headers: this.httpHeader }).pipe(map(data => data));
  }

  getCertifications() {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    return this.http.get<any[]>(`${BASE_URL}/api/certificates/${data.id}?limit=8&offset=0`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  public getTopValued() {
    return this.http.get<any[]>(`${BASE_URL}/api/topvalued`, { headers: httpOptions }).pipe(map(data => data));
  }

  public getProductsByCategory(category: any): Observable<any> {
    return this.http.get<any[]>(`${BASE_URL}/api/products-group`, { headers: httpOptions }).pipe(map(data => data));
  }

  public getCoursesByCustomer() {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    // console.log(this.httpHeader);
    return this.http.get<any[]>(`${BASE_URL}/api/subscriptions-customer/${data.id}?offset=0`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  public getEventsByCustomer() {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    // console.log(this.httpHeader);
    return this.http.get<any[]>(`${BASE_URL}/api/user-events/${data.id}`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  

  getProgressCourses(offset: any, limit: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    return this.http.get<any[]>(`${BASE_URL}/api/subscription-progress/${data.id}?offset=${offset}&limit=${limit}`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  getFinishedCourses(offset: any, limit: any) {
    let data = JSON.parse(localStorage["user_session"]);
    this.httpHeader = httpOptions.set('Authorization', data.token);
    return this.http.get<any[]>(`${BASE_URL}/api/subscription-finished/${data.id}?offset=${offset}&limit=${limit}`, { headers: this.httpHeader }).pipe(map(data => data));
  }

  public getProductsByTitle(category: any): Observable<any> {
    if (this.currentUser) this.httpHeader = httpOptions.set('Authorization', this.currentUser.token);
    return this.http.get<any>(`${BASE_URL}/api/products-title?title=${category}`, { headers: this.httpHeader });
  }


  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>(`${BASE_URL}/api/products`).pipe(map(data => data));
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.title.replace(' ', '-') === slug;
      });
    }));
  }


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Products[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Products[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false

    if (cartItem) {
      cartItem.quantity += qty
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Products[]) => {
      return product.reduce((prev, curr: Products) => {
        let price = Number.parseFloat(curr.UnitPrice);
        return (prev + price * curr.quantity) * this.Currency.price;
      }, 0);
    }));
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: any[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.Title < b.Title) {
          return -1;
        } else if (a.Title > b.Title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.Title > b.Title) {
          return -1;
        } else if (a.Title < b.Title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.UnitPrice < b.UnitPrice) {
          return -1;
        } else if (a.UnitPrice > b.UnitPrice) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.UnitPrice > b.UnitPrice) {
          return -1;
        } else if (a.UnitPrice < b.UnitPrice) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 8) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
